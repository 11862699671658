import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import useAltratecData from '../../context/useAltratecData';
import { getWhyAltratec } from '../../api/whyAltratec';
import Card from '../../components/Card/index';
import ReactMarkdownComponent from '../../components/ReactMarkdown';

const WhyAltratec = (props) => {
  const altratecData = useAltratecData();
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (altratecData) {
      setLoading(false);
    }
  }, [altratecData]);

  useEffect(() => {
    handleContent();
  }, []);

  const handleContent = async () => {
    try {
      const response = await getWhyAltratec();
      if (response?.data) {
        setContent(response.data);
      }
      setLoading(false);
    } catch (e) {
      console.error('Error fetching content:', e);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const { homeWhyAltratec } = altratecData;

  return (
    <MainContainer>
      <ReactMarkdownComponent>
        {homeWhyAltratec}
      </ReactMarkdownComponent>
      <CardContainer>
        {content.map((item, index) => {
          const { attributes } = item;
          const { title, description, image } = attributes || {};
          const imageUrl = image?.data?.attributes?.formats?.thumbnail?.url
            ? `${process.env.REACT_APP_API_URL}${image.data.attributes.url}`
            : '';
          const imageAlt =
            image?.data?.attributes?.name || 'Imagen sin descripción';

          return (
            <Card
              key={index}
              title={title}
              description={description}
              image={{
                url: imageUrl,
                alt: imageAlt,
              }}
            />
          );
        })}
      </CardContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  gap: 20,
}));

const CardContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '50px 0',
  gap: 50,
}));

export default WhyAltratec;
