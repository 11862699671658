import { styled } from '@mui/material';
import theme from '../../styles';

const PrimaryButton = (props) => {
  const Button = styled('button')(() => ({
    width: props.width || 'auto',
    padding: props.padding || '20px 90px',
    borderRadius: 10,
    color: props.color || theme.color.light,
    backgroundColor: props.backgroundColor || theme.color.accent,
    fontSize: '18px',
    fontWeight: '700',
    fontFamily: theme.fontFamily.secondary,
    transition: 'transform 0.3s ease-out',
    textTransform: 'uppercase',
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  }));

  return (
    <Button type={props.type || 'button'} onClick={props.onClick}>
      {props.children}
    </Button>
  );
};

export default PrimaryButton;
