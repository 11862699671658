import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useScroll } from '../ScrollContext'; // Provides section refs dynamically
import { styled } from '@mui/material';
import theme from '../../styles';
// import navbarItems from './educationNavbarItems.json';

const SubNavbar = (props) => {
  const scrollRefs = useScroll(); // Contains refs dynamically
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Scroll to top of the page when the path changes (navigation)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const scrollToSection = (scrollKey) => {
    const sectionRef = scrollRefs[scrollKey];
    if (sectionRef && sectionRef.current) {
      window.scrollTo({
        top: sectionRef.current.offsetTop - 73, // Adjust for navbar height
        behavior: 'smooth',
      });
    }
  };

  const handleNavClick = (path, scrollKey) => {
    const [basePath] = path.split('#'); // Extract base path
    if (scrollKey && scrollRefs[scrollKey]) {
      // Scroll to the section if a scrollKey is provided
      scrollToSection(scrollKey);
    } else {
      // Scroll to the top, then instantly navigate
      window.scrollTo(0, 0); // Reset scroll position
      setTimeout(() => {
        navigate(basePath, { replace: true });
      }, 0); // Allow scroll-to-top to apply before navigating
    }
  };

  // Handle scrolling automatically when the hash changes in the URL
  useEffect(() => {
    const hash = location.hash.replace('#', ''); // Remove the hash symbol
    const itemWithHash = props.navbarItems.find((item) =>
      item.path.endsWith(`#${hash}`)
    );
    if (itemWithHash?.scrollKey) {
      scrollToSection(itemWithHash.scrollKey);
    }
  }, [location.hash, scrollRefs]);


  return (
    <NavbarContainer scrollPosition={scrollPosition}>
      {props.children ? props.children : <GhostContainer />}
      <LinksContainer>
        {props.navbarItems.map((item, index) => (
          <NavLinkStyled
            hoverColor={props.hoverColor}
            key={index}
            to={item.path}
            onClick={(e) => {
              e.preventDefault(); // Prevent default navigation
              handleNavClick(item.path, item.scrollKey); // Navigate or scroll
            }}
          >
            {item.label}
          </NavLinkStyled>
        ))}
      </LinksContainer>
      <GhostContainer />
    </NavbarContainer>
  );
};

const NavbarContainer = styled('div')(({ scrollPosition }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 50,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 100px',
  transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
  backgroundColor:
    scrollPosition === 0 ? 'transparent' : 'rgba(255, 255, 255, 0.4)',
  backdropFilter: scrollPosition === 0 ? 'none' : 'blur(10px)',
  boxShadow: scrollPosition === 0 ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.1)',
  fontFamily: theme.fontFamily.secondary,
  color: theme.color.textPrimary,
  '@media (max-width: 768px)': {
    padding: '15px 50px',
  },
  '@media (max-width: 480px)': {
    padding: '10px 20px',
  },
}));

const LinksContainer = styled('div')({
  justifyContent: 'center',
  gap: '10px',
  flexWrap: 'wrap',
  '@media (max-width: 768px)': {
    gap: '8px',
  },
  '@media (max-width: 480px)': {
    gap: '5px',
  },
});
const GhostContainer = styled('div')({
});

const NavLinkStyled = styled(NavLink)(({ hoverColor }) => ({
  color: theme.color.textPrimary,
  textDecoration: 'none',
  padding: '10px 20px',
  fontSize: '20px',
  transition: 'transform 0.3s ease-out',
  '&:hover': {
    color: hoverColor || theme.color.primary,
    transform: 'translateY(-10px)',
  },
  '@media (max-width: 768px)': {
    fontSize: '18px',
    padding: '8px 16px',
  },
  '@media (max-width: 480px)': {
    fontSize: '16px',
    padding: '6px 12px',
  },
}));


export default SubNavbar;
