import React from 'react';
import { styled } from '@mui/material';
import theme from '../../styles';
import AltratecIsotypeRed from '../../assets/altratec-isotype-red.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import SlideImage1 from '../../assets/Slide1.png';
import SlideImage2 from '../../assets/sign-up-slide-image-round.png';
import SlideImage3 from '../../assets/log-in-slide-image.png';
import AccessImage from '../../assets/saed-access-image.png';
import AltratecButton from '../../components/AltratecButton';
import PrimaryButton from '../../components/PrimaryButton';

const AltratecSlider = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <AltratecLogo src={AltratecIsotypeRed} alt='Red letter A in a circle' />
        <TextContainer>
          <H4>Educación</H4>
          <H1>Altratec</H1>
          <H3>Alianza para la Transferencia Tecnológica Alemania-México!</H3>
        </TextContainer>
      </HeaderContainer>
      <Slides {...settings}>
        <SlideContainer>
          <Slide1>
            <ImageContainer>
              <Image src={SlideImage1} />
            </ImageContainer>
            <AltratecButtonContainer>
              <AltratecButton onClick={() => {}}>Saber más</AltratecButton>
            </AltratecButtonContainer>
          </Slide1>
        </SlideContainer>
        <SlideContainer>
          <Slide2>
            <SlideH1>Inscripciones abiertas</SlideH1>
            <SlideH2>¡Únete y cambia tu futuro profesional!</SlideH2>
            <ButtonContainer>
              <PrimaryButton>Carreras</PrimaryButton>
              <PrimaryButton>Cursos</PrimaryButton>
              <PrimaryButton>Bachillerato</PrimaryButton>
            </ButtonContainer>
            <GenericButtonContainer>
              <AltratecButton onClick={() => {}}>Registrarme</AltratecButton>
            </GenericButtonContainer>
            <BackgroundImage src={SlideImage2} />
          </Slide2>
        </SlideContainer>
        <SlideContainer>
          <Slide3>
            <AccessImageSAED src={AccessImage} alt='Acceso SAED' />
            <SlideH2>
              Accede con tu cuenta al
              <br />
              Sistema de Administración
              <br />
              Educación Dual
            </SlideH2>
            <GenericButtonContainer>
              <AltratecButton onClick={() => {}}>Entrar</AltratecButton>
            </GenericButtonContainer>
            <BackgroundImage src={SlideImage3} />
          </Slide3>
        </SlideContainer>
      </Slides>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  padding: '50px 100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media (max-width: 768px)': {
    padding: '30px 50px',
  },
  '@media (max-width: 480px)': {
    padding: '20px',
  },
}));

const AltratecLogo = styled('img')(() => ({
  padding: '20px 0',
}));

const AccessImageSAED = styled('img')(() => ({
  width: '20vw',
  maxWidth: '300px',
}));

const HeaderContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 20,
  padding: '0 200px',
  '@media (max-width: 768px)': {
    padding: '0 100px',
  },
  '@media (max-width: 480px)': {
    padding: '0 20px',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
}));

const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 45,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
  '@media (max-width: 768px)': {
    fontSize: 35,
  },
  '@media (max-width: 480px)': {
    fontSize: 28,
  },
}));

const SlideH1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
  textShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '@media (max-width: 768px)': {
    fontSize: 32,
  },
  '@media (max-width: 480px)': {
    fontSize: 24,
  },
}));

const SlideH2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 20,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
  textShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '@media (max-width: 768px)': {
    fontSize: 18,
  },
  '@media (max-width: 480px)': {
    fontSize: 16,
  },
}));

const H3 = styled('h3')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 18,
  fontStyle: 'italic',
  color: theme.color.textPrimary,
}));

const H4 = styled('h4')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
}));

const Slides = styled(Slider)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}));

const SlideContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const AltratecButtonContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0 200px',
  marginTop: -20,
  '@media (max-width: 768px)': {
    padding: '0 100px',
  },
  '@media (max-width: 480px)': {
    padding: '0 20px',
  },
}));

const GenericButtonContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: -20,
}));

const Slide1 = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
}));

const Slide2 = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  boxSizing: 'border-box',
  width: 'calc(100vw - 200px)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 70,
  padding: '100px 200px',
  '@media (max-width: 768px)': {
    padding: '60px 100px',
    gap: 50,
  },
  '@media (max-width: 480px)': {
    padding: '40px 20px',
    width: '100%',
  },
}));

const Slide3 = styled(Slide2)({});

const BackgroundImage = styled('img')(() => ({
  width: '50%',
  maxWidth: '500px',
  position: 'absolute',
  top: '10%',
  right: '200px',
  padding: '0 0 200px 0',
  zIndex: -10,
  '@media (max-width: 768px)': {
    right: '100px',
  },
  '@media (max-width: 480px)': {
    right: '20px',
    width: '80%',
  },
}));

const ImageContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Image = styled('img')(() => ({
  width: '100%',
  maxWidth: '1500px',
  padding: '0 200px',
  '@media (max-width: 768px)': {
    padding: '0 100px',
  },
  '@media (max-width: 480px)': {
    padding: '0 20px',
  },
}));

const ButtonContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 20,
}));

export default AltratecSlider;
