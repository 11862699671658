import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Education from '../pages/Education';
import NoMatch from '../pages/NoMatch';
import TermsAndConditions from '../pages/TermsAndConditions';
import PrivacyNotice from '../pages/PrivacyNotice';
import Contact from '../pages/Contact';
import EducationAltratec from '../pages/Education/Altratec';
import AltratecContact from '../pages/Education/Altratec/Contact';
import AltratecServicesPage from '../pages/Education/Altratec/Services';
import AltratecAdmissions from '../pages/Education/Altratec/Admissions';
import AltratecCareers from '../pages/Education/Altratec/Careers';
// import AltratecCourses from '../pages/Education/Altratec/Cursos';
import AltratecBachelorsDegree from '../pages/Education/Altratec/BachelorsDegree';

const Router = (props) => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path='home' element={<Home />} />
      {/* <Route path='home' element={<Home />} /> */}
      <Route path='education' element={<Education />} />
      <Route path='education/home' element={<Education />} />
      {/* <Route path='education/*' element={<EducationNoMatch />} /> */}
      <Route path='education/altratec' element={<EducationAltratec />} />
      <Route path='education/altratec/home' element={<EducationAltratec />} />
      <Route
        path='education/altratec/services'
        element={<AltratecServicesPage />}
      />
      <Route
        path='education/altratec/admissions'
        element={<AltratecAdmissions />}
      />
      <Route path='education/altratec/careers' element={<AltratecCareers />} />
      {/* <Route path='education/altratec/courses' element={<AltratecCourses />} /> */}
      <Route
        path='education/altratec/bachelors-degree'
        element={<AltratecBachelorsDegree />}
      />
      <Route path='education/altratec/contact' element={<AltratecContact />} />
      {/* <Route path='education/altratec/*' element={<AltratecNoMatch />} /> */}
      <Route path='contact' element={<Contact />} />
      <Route path='terms-and-conditions' element={<TermsAndConditions />} />
      <Route path='privacy-notice' element={<PrivacyNotice />} />
      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
};

export default Router;
