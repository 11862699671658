import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material';
import theme from '../../styles';

const AltratecNavbar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <NavbarContainer scrollPosition={scrollPosition}>
      <LinksContainer>
        <NavLinkStyled to='/education/altratec/home' className='nav-link'>
          Inicio
        </NavLinkStyled>
        <NavLinkStyled to='/education/altratec/services' className='nav-link'>
          Servicios
        </NavLinkStyled>
        <NavLinkStyled to='/education/altratec/admissions' className='nav-link'>
          Admisión
        </NavLinkStyled>
        <NavLinkStyled to='/education/altratec/careers' className='nav-link'>
          Carreras
        </NavLinkStyled>
        {/* <NavLinkStyled to='/education/altratec/courses' className='nav-link'>
          Cursos
        </NavLinkStyled> */}

        <NavLinkStyled
          to='/education/altratec/bachelors-degree'
          className='nav-link'
        >
          Bachillerato
        </NavLinkStyled>
        <NavLinkStyled to='/education/altratec/contact' className='nav-link'>
          Contacto
        </NavLinkStyled>
      </LinksContainer>
    </NavbarContainer>
  );
};

// Styled component using Material-UI's styled function
const NavbarContainer = styled('div')(({ scrollPosition }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 50,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 100px',
  transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
  backgroundColor:
    scrollPosition === 0 ? 'transparent' : 'rgba(255, 255, 255, 0.4)',
  backdropFilter: scrollPosition === 0 ? 'none' : 'blur(10px)',
  boxShadow: scrollPosition === 0 ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.1)',
  fontFamily: theme.fontFamily.secondary,
  color: theme.color.textPrimary,
}));

const LinksContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
});

const NavLinkStyled = styled(NavLink)({
  color: theme.color.textPrimary,
  textDecoration: 'none',
  padding: '10px 20px',
  fontSize: '20px',
  transition: 'transform 0.3s ease-out',
  '&:hover': {
    color: theme.color.primary,
    transform: 'translateY(-10px)',
  },
});

export default AltratecNavbar;
