import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/index.jsx';
import { getGlobal } from '../../api/global';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/material';
import theme from '../../styles';
import Lottie from 'lottie-react';
import LoadingAnimation from '../../assets/lottie/Animation - 1719783273631.json';

const PrivacyNotice = () => {
  const [loading, setLoading] = useState(true);
  const [privacyText, setPrivacyText] = useState('');

  useEffect(() => {
    const fetchPrivacyData = async () => {
      try {
        const response = await getGlobal();
        if (response && response.data && response.data.attributes) {
          setPrivacyText(response.data.attributes.privacyPolicy);
        } else {
          console.error('Privacy notice not found in API response');
        }
      } catch (error) {
        console.error('Error fetching privacy notice:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrivacyData();
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <Lottie
          animationData={LoadingAnimation}
          loop={true}
          style={{ maxWidth: '200px', margin: 50 }}
        />
      </LoadingContainer>
    );
  }

  if (!privacyText) {
    return <div>No privacy notice available</div>;
  }

  return (
    <MainContainer>
      <Navbar color={theme.color.primary} />
      <TextContainer>
        <ReactMarkdown components={markdownComponents}>
          {privacyText}
        </ReactMarkdown>
      </TextContainer>
    </MainContainer>
  );
};

const markdownComponents = {
  h1: (props) => <H1 {...props} />,
  h2: (props) => <H2 {...props} />,
  p: (props) => <Paragraph {...props} />,
  a: (props) => <Link {...props} />,
};

const LoadingContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}));

const MainContainer = styled('div')(() => ({
  backgroundColor: theme.color.brick,
  padding: '50px 100px',
  display: 'flex',
  justifyContent: 'center',
  '@media (max-width: 600px)': {
    padding: '70px 20px',
  },
}));

const TextContainer = styled('div')(() => ({
  maxWidth: '800px',
  color: theme.color.textPrimary,
  lineHeight: '1.8',
  fontFamily: theme.fontFamily.secondary,
  fontSize: '16px',
}));

const H1 = styled('h1')(() => ({
  color: theme.color.textPrimary,
  fontFamily: theme.fontFamily.primary,
  fontWeight: 'bold',
  fontSize: '36px',
  textAlign: 'center',
  margin: '20px 0',
  '@media (max-width: 600px)': {
    fontSize: '28px',
  },
}));

const H2 = styled('h2')(() => ({
  color: theme.color.textPrimary,
  fontFamily: theme.fontFamily.primary,
  fontWeight: '600',
  fontSize: '28px',
  textAlign: 'left',
  margin: '15px 0',
  '@media (max-width: 600px)': {
    fontSize: '24px',
  },
}));

const Paragraph = styled('p')(() => ({
  color: theme.color.textPrimary,
  fontFamily: theme.fontFamily.secondary,
  fontSize: '16px',
  margin: '10px 0',
}));

const Link = styled('a')(() => ({
  color: theme.color.primary,
  textDecoration: 'none',
  fontWeight: '600',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export default PrivacyNotice;
