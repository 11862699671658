import { styled } from '@mui/material';
import theme from '../../styles';
import { useScroll } from '../../components/ScrollContext';

const EducationAboutUs = (props) => {
  const { aboutUs } = useScroll();

  return (
    <MainContainer ref={aboutUs}>
      <H2>NICDET</H2>
      <H1>Nosotros</H1>
      <Paragraph>
        NICDET cuenta con infraestructura especializada para llevar a cabo
        actividades de investigación, habilitado con equipamiento de última
        tecnología que proporciona herramientas de gran utilidad para la
        creación de nuevo conocimiento y el avance tecnológico. El personal de
        los laboratorios ofrece servicios de alto nivel en la asesoría y
        operación de equipos así como en la supervisión del adecuado
        funcionamiento de las instalaciones. Asimismo, NICDET fomenta la
        vinculación con instituciones educativas de nivel superior, promoviendo
        convenios de colaboración académica e investigación para el desarrollo
        de proyectos específicos donde se busca complementar las capacidades de
        infraestructura y recursos humanos.
      </Paragraph>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  [`@media (max-width: 600px)`]: {
    padding: '20px',
  },
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const H2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
}));
const Paragraph = styled('p')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));

export default EducationAboutUs;
