import MainNavbar from '../../../../components/MainNavbar';
import AltratecNavbar from '../../../../components/AltratecNavbar';
import AltratecHeader from '../../../../sections/AltratecHeader';
import ContactSection from '../../../../sections/ContactSection';
import CoursesHeader from '../../../../sections/CoursesHeader';

function AltratecServicesPage() {
  return (
    <>
      <MainNavbar />
      <AltratecHeader />
      <AltratecNavbar />
      <CoursesHeader />
      <ContactSection />
    </>
  );
}

export default AltratecServicesPage;
