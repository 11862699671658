//Creates a receipt (uploads it) with given data by the user.
async function getGlobal() {
    try {
      let requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
        redirect: 'follow',
      };
  
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/nc-global?populate=*`,
        requestOptions
      );
      return await response.json();
    } catch (error) {
      throw error;
    }
  }
  
  export { getGlobal };
  