import MainNavbar from '../../../components/MainNavbar';
import AltratecHeader from '../../../sections/AltratecHeader';
import AltratecSlider from '../../../sections/AltratecSlider';
import WhyAltratec from '../../../sections/WhyAltratec';
import AltratecServices from '../../../sections/AltratecServices';
import AltratecOperations from '../../../sections/AltratecOperations';
import AltratecFormationProgram from '../../../sections/AltratecFormationProgram';
import AltratecHistory from '../../../sections/AltratecHistory';
import AltratecCapabilities from '../../../sections/AltratecCapabilities';
import AltratecCollaborations from '../../../sections/AltratecCollaborations';
import ContactSection from '../../../sections/ContactSection';
import AltratecNavbarItems from '../../../routes/altratecNavbarItems.json';
import SubNavbar from '../../../components/SubNavbar';
import { styled } from '@mui/material';
import AltratecIsotypeRed from '../../../assets/altratec-isotype-red.svg';
import theme from '../../../styles';
function EducationAltratec() {
  return (
    <>
      <MainNavbar />
      <AltratecHeader />
      <SubNavbar navbarItems={AltratecNavbarItems} hoverColor={theme.color.red}>
        <AltratecLogo src={AltratecIsotypeRed} alt='Red letter A in a circle' />
      </SubNavbar>
      <AltratecSlider />
      <WhyAltratec />
      <AltratecServices />
      <AltratecOperations />
      <AltratecFormationProgram />
      <AltratecHistory />
      <AltratecCapabilities />
      <AltratecCollaborations />
      <ContactSection />
    </>
  );
}
const AltratecLogo = styled('img')(() => ({
  width: 45,
}));
export default EducationAltratec;
