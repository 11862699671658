import React from 'react';
import { styled } from '@mui/material';
import theme from '../../styles';
import ReactMarkdownComponent from '../ReactMarkdown';

const Card = (props) => {

  return (
    <MainContainer >
      <H2>{props.title}</H2>
      <ReactMarkdownComponent>{props.description}</ReactMarkdownComponent>
      {props.image ? (<GrowContainer>
        <Image
          src={props.image.url}
          alt={props.image.alt} />
      </GrowContainer>) : null

      }

    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '33.33%',
  flex: 1,
  display: 'flex',
  flexGrow: 1,
  alignSelf: 'stretch',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 20,
  padding: 20,
  borderRadius: '5px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
}));

const GrowContainer = styled('div')(() => ({
  width: '100%',
  flex: 1,
  display: 'flex',
  flexGrow: 1,
  alignSelf: 'stretch',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  gap: 20,
}));

const H2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));

const Image = styled('img')(() => ({
  width: '100%',
}));

export default Card;
