import React, { createContext, useState, useEffect } from 'react';
import { getAltratec } from '../api/altratec';

export const AltratecDataContext = createContext(null);

const AltratecDataProvider = ({ children }) => {
  const [altratecData, setAltratecData] = useState(null);

  useEffect(() => {
    const fetchAltratecData = async () => {
      try {
        const response = await getAltratec();

        if (response.data.attributes) {
          setAltratecData(response.data.attributes); // Assuming the structure from your response
        } else {
          console.error('Context fetch failed');
        }
      } catch (error) {
        console.error('Failed to fetch altratec data:', error);
      }
    };

    fetchAltratecData();
  }, []);

  return (
    <AltratecDataContext.Provider value={altratecData}>
      {children}
    </AltratecDataContext.Provider>
  );
};

export default AltratecDataProvider;
