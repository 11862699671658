import { styled } from '@mui/material';
import MainHeroBackgroundImage from '../../assets/education-header.png';
import theme from '../../styles';

const EducationHeader = () => {
  return (
    <MainContainer>
      <TopContainer>
        <H1>Educación</H1>
      </TopContainer>
      <BottomContainer>
        <H3>Modelo de formación profesional Alemán</H3>
        <H2>¡Ahora en México!</H2>
      </BottomContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  position: 'relative',
  width: '100%',
  height: '70svh',
  backgroundImage: `url(${MainHeroBackgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: '100px 100px 50px 100px',
  gap: 50,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
  '@media (max-width: 1200px)': {
    padding: '80px 80px 40px 80px',
    gap: 40,
  },
  '@media (max-width: 900px)': {
    padding: '60px 60px 30px 60px',
    height: '60svh',
    gap: 30,
  },
  '@media (max-width: 600px)': {
    padding: '40px 40px 20px 40px',
    height: '50svh',
    gap: 20,
  },
}));

const TopContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    justifyContent: 'center',
  },
}));

const BottomContainer = styled('div')(() => ({
  width: '30%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 900px)': {
    width: '70%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
}));

const H1 = styled('h1')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.tertiary,
  fontWeight: 'bold',
  fontSize: 70,
  textAlign: 'right',
  '@media (max-width: 1200px)': {
    paddingTop: 50,
    fontSize: 60,
  },
  '@media (max-width: 900px)': {
    fontSize: 50,
    textAlign: 'center',
  },
  '@media (max-width: 600px)': {
    fontSize: 40,
    textAlign: 'center',
  },
}));

const H2 = styled('h2')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.tertiary,
  fontWeight: '800',
  fontSize: 45,
  textAlign: 'left',
  '@media (max-width: 1200px)': {
    fontSize: 40,
  },
  '@media (max-width: 900px)': {
    fontSize: 35,
    textAlign: 'center',
  },
  '@media (max-width: 600px)': {
    fontSize: 30,
    textAlign: 'center',
  },
}));

const H3 = styled('h3')(() => ({
  color: 'white',
  fontFamily: theme.fontFamily.tertiary,
  fontWeight: '500',
  fontSize: 35,
  textAlign: 'left',
  '@media (max-width: 1200px)': {
    fontSize: 30,
  },
  '@media (max-width: 900px)': {
    fontSize: 25,
    textAlign: 'center',
  },
  '@media (max-width: 600px)': {
    fontSize: 20,
    textAlign: 'center',
  },
}));

export default EducationHeader;
