import { styled } from '@mui/material';
import theme from '../../styles';
import imageKonstrulab from '../../assets/konstrulab.png';

const CoursesHeader = (props) => {
  return (
    <MainContainer>
      <HeaderContainer>
        <TextContainer>
          <H1>Nuestras carreras</H1>
        </TextContainer>
      </HeaderContainer>

      <CardContainer>
        <Card>
          <Image2 src={imageKonstrulab} alt='Estudiante y laptop' />
          <Line />
          <H4>Carrera</H4>
          <H3>Ingeniería en Sistemas Computacionales</H3>
          <Paragraph>
            Forma profesionales en el diseño, desarrollo y mantenimiento de
            sistemas informáticos. Aborda programación, bases de datos, redes y
            ciberseguridad, preparando para desafíos tecnológicos actuales.
          </Paragraph>
          <button>Leer más</button>
        </Card>
        <Card>
          <Image2 src={imageKonstrulab} alt='Estudiante y laptop' />
          <Line />
          <H4>Carrera</H4>
          <H3>Ingeniería en Mecatrónica</H3>
          <Paragraph>
            Combina mecánica, electrónica y computación para crear sistemas
            automatizados. Forma expertos en robótica, control industrial y
            diseño de máquinas inteligentes, listos para la industria moderna.
          </Paragraph>
          <button>Leer más</button>
        </Card>
      </CardContainer>
      <HeaderContainer>
        <TextContainer>
          <H1>Ingeniería en Sistemas Computacionales</H1>
        </TextContainer>
      </HeaderContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 20,
}));
const HeaderContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 40,
  padding: '20px 100px',
}));
const TextContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 15,
}));
const Line = styled('div')(() => ({
  position: 'relative',
  // top: '50px',
  width: '100%',
  marginTop: '-70px',
  marginBottom: '50px',
  zIndex: '-1',
  borderBottom: '1px solid #444444',
}));
const CardContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 100px',
  gap: 50,
}));
const Card = styled('div')(() => ({
  width: '50%',
  display: 'flex',
  flexGrow: 1,
  alignSelf: 'stretch',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 20,
  padding: 20,
  borderRadius: '5px',
  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const H3 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const H4 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 14,
  color: theme.color.textPrimary,
}));
const Paragraph = styled('p')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));
const Image2 = styled('img')(() => ({
  maxWidth: '100px',
  // padding: '10px 70px 0',
}));

export default CoursesHeader;
