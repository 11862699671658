import React from 'react';
import boulderGridLogo from '../../assets/nicdet-logotype.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div
      className={'footer-main-container'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: 'linear-gradient(90deg, #0092FB 0%, #155787 100%)',
        padding: '20px',
      }}
    >
      <a href='/' className='hover'>
        <img
          src={boulderGridLogo}
          className='logotype'
          alt='reliz-vector'
          style={{ marginBottom: '20px' }}
        />
      </a>
      <div
        className='footer-items-container flex flex-col items-center'
        style={{ marginBottom: '20px' }}
      >
        <a
          href='/privacy-notice'
          className='hover'
          style={{ marginBottom: '10px' }}
        >
          Aviso de privacidad
        </a>
        <a href='/terms-and-conditions' className='hover'>
          Términos y condiciones
        </a>
      </div>
      <div className='social-media-container flex flex-row justify-center'>
        <a
          href='https://www.instagram.com/nicdet_mx/'
          target='_blank'
          rel='noopener noreferrer'
          className='hover'
          style={{ marginRight: '10px' }}
        >
          <FontAwesomeIcon icon={faInstagram} size='xl' />
        </a>
      </div>
    </div>
  );
};

export default Footer;
