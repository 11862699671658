import React, { createContext, useContext, useRef } from 'react';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const sectionRefs = {
    aboutUs: useRef(null),
    educationContact: useRef(null),
    collaborations: useRef(null),
    events: useRef(null),
    memberships: useRef(null),
    specializedTraining: useRef(null),
  };

  return (
    <ScrollContext.Provider value={sectionRefs}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);
