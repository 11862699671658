import { styled } from '@mui/material';
import theme from '../../styles';

const TitleAndParagraphs = ({ title, paragraphs, list, padding }) => {
  return (
    <MainContainer padding={padding}>
      <H1>{title}</H1>
      {paragraphs?.map((paragraph, index) => (
        <Paragraph key={index}>{paragraph}</Paragraph>
      ))}
      {list ? (
        <ul>
          {list.map((list, index) => (
            <li key={index}>{list}</li>
          ))}
        </ul>
      ) : null}
    </MainContainer>
  );
};

const MainContainer = styled('div')(({ padding }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: padding || '50px 100px',
  gap: 20,
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const Paragraph = styled('p')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));

export default TitleAndParagraphs;
