import React from 'react';
import { styled } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import theme from '../../styles';

const ReactMarkdownComponent = ({ children, textColor, boldColor }) => {
  return (
    <ReactMarkdown
      components={markdownComponents(textColor, boldColor)}
      textColor={textColor}
    >
      {children}
    </ReactMarkdown>
  );
};

const markdownComponents = (textColor, boldColor) => ({
  h1: (props) => <H1 {...props} textColor={textColor} />,
  h2: (props) => <H2 {...props} textColor={textColor} />,
  p: (props) => <Paragraph {...props} textColor={textColor} />,
  a: (props) => <Link {...props} textColor={textColor} />,
  strong: (props) => <BoldText {...props} boldColor={boldColor} />, // Added bold styling
});

// Styled Components

const H1 = styled('h1')(({ textColor }) => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: textColor || theme.color.textPrimary,
}));

const H2 = styled('h2')(({ textColor }) => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  fontWeight: 'bold',
  color: textColor || theme.color.textPrimary,
}));

const Paragraph = styled('p')(({ textColor }) => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: textColor || theme.color.textPrimary,
  textAlign: 'left',
}));

const Link = styled('a')(({ textColor }) => ({
  color: textColor || theme.color.textPrimary,
  textDecoration: 'none',
  fontWeight: '600',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

// New styled component for bold text
const BoldText = styled('strong')(({ boldColor }) => ({
  fontWeight: 'bold',
  color: boldColor || theme.color.textPrimary,
}));

export default ReactMarkdownComponent;