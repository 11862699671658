import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import theme from '../../../../styles';
import ReactMarkdown from 'react-markdown';
import MainNavbar from '../../../../components/MainNavbar';
import AltratecNavbar from '../../../../components/AltratecNavbar';
import AltratecHeader from '../../../../sections/AltratecHeader';
import ContactSection from '../../../../sections/ContactSection';
import ServicesData from '../../../../data/services';
import TitleAndParagraphs from '../../../../sections/TitleAndParagraphs';
import useAltratecData from '../../../../context/useAltratecData';

function AltratecCareers() {
  const altratecData = useAltratecData();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (altratecData) {
      setLoading(false);
    }
  }, [altratecData]);

  if (loading) {
    return <p>Loadin...</p>;
  }
  const { vinculationServicesTitle, vinculationServicesDescription, vinculationServicesImage} =
    altratecData;

  return (
    <>
      <MainNavbar />
      <AltratecHeader />
      <AltratecNavbar />
      <MainContainer>
        <Title>{vinculationServicesTitle}</Title>
        <ReactMarkdown components={markdownComponents}>
          {vinculationServicesDescription}
        </ReactMarkdown>
        <ImageContainer>
        <Image src={`${process.env.REACT_APP_API_URL}${vinculationServicesImage.data.attributes.formats.large.url}`} alt='Red letter A in a circle' />
        </ImageContainer>
      </MainContainer>
      {ServicesData.map((service, index) => (
        <TitleAndParagraphs
          key={index}
          title={service.name}
          paragraphs={service.paragraphs}
        />
      ))}
      <ContactSection />
    </>
  );
}

const Image = styled('img')(() => ({
  width: '100%',
  maxWidth: '1000px'
}));

const ImageContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}));

const markdownComponents = {
  h1: (props) => <H1 {...props} />,
  h2: (props) => <H2 {...props} />,
  p: (props) => <Paragraph {...props} />,
  a: (props) => <Link {...props} />,
};
const MainContainer = styled('div')(({ padding }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: padding || '50px 100px',
  gap: 20,
}));
const Title = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const H1 = styled('h1')(() => ({
  color: theme.color.textPrimary,
  fontFamily: theme.fontFamily.primary,
  fontWeight: 'bold',
  fontSize: '36px',
  textAlign: 'center',
  margin: '20px 0',
  '@media (max-width: 600px)': {
    fontSize: '28px',
  },
}));

const H2 = styled('h2')(() => ({
  color: theme.color.textPrimary,
  fontFamily: theme.fontFamily.primary,
  fontWeight: '600',
  fontSize: '28px',
  textAlign: 'left',
  margin: '15px 0',
  '@media (max-width: 600px)': {
    fontSize: '24px',
  },
}));

const Paragraph = styled('p')(() => ({
  color: theme.color.textPrimary,
  fontFamily: theme.fontFamily.secondary,
  fontSize: '16px',
  margin: '10px 0',
}));

const Link = styled('a')(() => ({
  color: theme.color.primary,
  textDecoration: 'none',
  fontWeight: '600',
  '&:hover': {
    textDecoration: 'underline',
  },
}));
export default AltratecCareers;
