import { useEffect } from 'react';
import AuthProvider from './auth/authProvider';
import './App.css';
import Router from './routes/router.js';
import Footer from './sections/Footer/index.jsx';

import { ScrollProvider } from './components/ScrollContext.jsx';
import AltratecDataProvider from './context/altratecDataProvider';

function App() {
  useEffect(() => {
    console.log('printing');
  }, []);

  return (
    <AuthProvider>
      <AltratecDataProvider>
        <ScrollProvider>
          <Router></Router>
          <Footer></Footer>
        </ScrollProvider>
      </AltratecDataProvider>
    </AuthProvider>
  );
}

export default App;
