import React from 'react';
import { styled } from '@mui/material';
import theme from '../../styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import EventsData from '../../data/events';
import { useScroll } from '../../components/ScrollContext';

const EventSlider = (props) => {
  const { events } = useScroll();

  const getRandomAutoplaySpeed = () => {
    return Math.floor(Math.random() * (4500 - 3500 + 1)) + 3500;
  };

  const sortedEvents = [...EventsData].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  const eventChunks = chunkArray(sortedEvents, 4);

  return (
    <MainContainer ref={events}>
      <H1>Nuestros eventos recientes</H1>
      <Gallery>
        {eventChunks.map((chunk, rowIndex) => (
          <RowContainer key={rowIndex}>
            {chunk.map((event, eventIndex) => {
              const images =
                event.images.length === 1
                  ? [...event.images, event.images[0]]
                  : event.images;
              const settings = {
                dots: event.images.length > 1,
                infinite: true,
                speed: 1500,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: event.images.length > 1,
                autoplaySpeed: getRandomAutoplaySpeed(),
              };

              return (
                <Slides key={eventIndex} {...settings}>
                  {images.map((image, imageIndex) => (
                    <SlideContainer key={imageIndex}>
                      <Slide>
                        <Image src={image} alt={`evento ${event.name}`} />
                      </Slide>
                    </SlideContainer>
                  ))}
                </Slides>
              );
            })}
          </RowContainer>
        ))}
      </Gallery>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 20,
}));

const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
  padding: '0px 100px',
  '@media (max-width: 600px)': {
    padding: '0px 20px',
    fontSize: 30,
  },
}));

const Gallery = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'black',
}));

const RowContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
}));

const Slides = styled(Slider)(() => ({
  width: '25%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}));

const SlideContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Slide = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Image = styled('img')(() => ({
  height: 300,
  width: 400,
  objectFit: 'cover',
  '@media (max-width: 600px)': {
    width: '100%',
    height: 'auto',
  },
}));

export default EventSlider;
