import { styled } from '@mui/material';
import MainHeroBackgroundImage from '../../assets/altratec-header.png';
import theme from '../../styles';
import AltratecIsotypeWhite from '../../assets/altratec-isotype-white.svg';

const AltratecHeader = ({ props }) => {
  const MainContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    width: '100%',
    height: '70svh', // Adjust height as needed
    backgroundImage: `url(${MainHeroBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '100px 100px 50px 100px',
    // gap: 50,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% opacity black
      zIndex: 1,
    },
    '& > *': {
      position: 'relative',
      zIndex: 2,
    },
  }));
  const TopContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }));
  const BottomContainer = styled('div')(() => ({
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '@media (max-width: 1300px)': {
      width: '100%', // Adjust width for small screens
    },
  }));
  const H1 = styled('h1')(() => ({
    color: 'white',
    fontFamily: theme.fontFamily.tertiary,
    fontWeight: 'bold',
    fontSize: 60,
    textAlign: 'right',
    '@media (max-width: 1300px)': {
      fontSize: 50,
    },
  }));
  const H2 = styled('h2')(() => ({
    color: 'white',
    fontFamily: theme.fontFamily.tertiary,
    fontWeight: '800',
    fontSize: 45,
    textAlign: 'left',
    '@media (max-width: 1300px)': {
      fontSize: 35,
    },
  }));
  const H3 = styled('h3')(() => ({
    color: 'white',
    fontFamily: theme.fontFamily.tertiary,
    fontWeight: '500',
    fontSize: 30,
    textAlign: 'left',
    '@media (max-width: 1300px)': {
      fontSize: 25,
    },
  }));
  const AltratecLogo = styled('img')(() => ({
    padding: '20px 0',
    '@media (max-width: 1300px)': {
      width: '10%',
      minWidth: 70,
    },
  }));

  return (
    <MainContainer>
      <TopContainer>
        <H1>Altratec</H1>
      </TopContainer>
      <BottomContainer>
        <AltratecLogo
          src={AltratecIsotypeWhite}
          alt='White letter A in a circle'
        ></AltratecLogo>
        <H3>Modelo de formación profesional Alemán</H3>
        <H2>¡Ahora en México!</H2>
      </BottomContainer>
    </MainContainer>
  );
};

export default AltratecHeader;
