import MainNavbar from '../../components/MainNavbar';
// import EducationNavbar from '../../components/EducationNavbar';
import EducationHeader from '../../sections/EducationHeader';
import EducationAboutUs from '../../sections/EducationAboutUs';
import AltratecSlider from '../../sections/AltratecSlider';
import EducationSpecializedTraining from '../../sections/EducationSpecializedTraining';
import EducationCollaboration from '../../sections/EducationCollaboration';
import ContactSection from '../../sections/ContactSection';
import EventSlider from '../../sections/EventSlider';
import SubNavbar from '../../components/SubNavbar';
import EducationNavbarItems from '../../routes/educationNavbarItems.json'

function Education() {
  return (
    <>
      <MainNavbar />
      <EducationHeader />
      {/* <EducationNavbar /> */}
      <SubNavbar navbarItems={EducationNavbarItems}/>
      <EducationAboutUs />
      <AltratecSlider />
      <EducationSpecializedTraining />
      <EducationCollaboration />
      <EventSlider />
      <ContactSection />
    </>
  );
}

export default Education;
