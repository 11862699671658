//All Authentication API's functions are found in this document
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { REACT_APP_API_URL, REACT_APP_API_KEY } from '@env'; //Library needed to obtain the REACT_APP_API_URL contained in the .env file.

//Each function that has accessToken as an argument, needs to be executed with a recent user login. Otherwise, it will not work.
//A unique accessToken is generated whenever the user is authorized.

//Authenticates the user with given Credentials. It returns an accessToken. (Used at Login)
async function userAuth(userData) {
  try {
    let requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: userData,
      redirect: 'follow',
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/auth/local`,
      requestOptions
    );
    return await response.json();
  } catch (error) {
    throw error;
  }
}

//Function that logs the user out and empties the session. (Used in Header Component)
async function logOut(setSession, setIsAuthorized, setJustLoggedOut) {
  try {
    setJustLoggedOut(true);

    //Clear Storage
    // await AsyncStorage.removeItem('@session');
    // await AsyncStorage.removeItem('@isAuth');
    // await AsyncStorage.removeItem('@loggedIn');

    //Empty Session
    setSession({});
    setIsAuthorized(false);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export { userAuth, logOut };
